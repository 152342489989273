import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'

const ConactoPage = () => (
  <Layout>
    <h1>CONTACTO</h1>
    <p>Coming soon...</p>
    <Link to="/">Back</Link>
  </Layout>
)

export default ConactoPage
